import React from 'react';
import Partners from "../components/about/partner";
import OurTeam from "../components/about/OurTeam";
import OurClients from "../components/about/ourClients";

const AboutUs = () => {

  return (
      <div className="about_body">
         <Partners />
         <OurTeam />
         <OurClients />
      </div>
  );
};

export default AboutUs;
