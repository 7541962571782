import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer_bottom">
      <div className="footer-link-contact">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 footer-link">
              <div className="footer-title">
                <h3>CONTACT</h3>
              </div>
              <div className="footer-content">
                <p>
                  <span>Contact</span>
                  <a href="tel:8988864888" className="mb-2">89888 64888</a>
                  <a href="tel:8988865888" className="mb-2">89888 65888</a>
                  {/* <a href="tel:99411787014" className="mb-2">89888 64888</a>
                  <a href="tel:99411787014">89888 65888</a> */}
                </p>
                <p>
                  <span>Email  id : </span>
                  <span>info@centralbusinesshub.in</span>
                </p>
                <p>
                  <span>Address</span>
                  <br />
                  <span>Central Business Hub, Opp. Rajhans Ornate,Parle Point, Surat, Gujarat -395007.</span>
                </p>
                <p>
                  <span>Website</span>
                  <br />
                  <span>www.centralbusinesshub.in</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 footer-link">
              <div className="footer-title">
                <h3>USEFUL LINKS</h3>
              </div>
              <div className="footer-content">
                <Link to="/cbh">CBH</Link>

                <Link to="/past-project-list">Past Projects</Link>
                {/* <Link to="/cbh-project-status">Past Projects</Link> */}
                <Link to="/cbh-project-status">Project Status</Link>
                <p>RERA NO : PR/GJ/SURAT/SURAT CITY/SUDA/CAA08679/280621</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
              <div className="footer-title1">
                {/* <h3>Project By Snynergy Group And Swami Group</h3> */}
                <h3 style={{ fontSize: '28px' }}>Project By</h3>
                <img src="/assets/SYNERGY-Logo.png" alt="snynergylogo" width='90%' style={{ marginTop: '20px' }} />
                <img src="/assets/swami-logo.png" alt="swamilogo" width='90%' style={{ marginTop: '20px' }} />
              </div>
            </div>
            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 footer-link">
              <div className="footer-title">
                <h3>ENQUIRE</h3>
              </div>
              <div className="footer-content">
                <Form>
                  <Form.Group>
                    <Form.Control type="text" placeholder="NAME" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="E-MAIL" />
                  </Form.Group>
                  <Button variant="outline-light" className="send-enquery">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      x="0px"
                      y="0px"
                      width="74px"
                      height="40px"
                      viewBox="0 0 74 40"
                    >
                      <g>
                        <circle
                          opacity="0.5"
                          fill="none"
                          stroke="#C28562"
                          cx="71%"
                          cy="50%"
                          r="24%"
                        ></circle>
                        <circle
                          fill="none"
                          stroke="#C28562"
                          cx="71%"
                          cy="50%"
                          r="24%"
                        ></circle>
                      </g>
                      <polygon
                        fill="#C28562"
                        points="49.525,14.265 48.898,15.044 54.481,19.541 6.444,19.541 6.444,20.541 54.464,20.541 48.901,24.954 49.522,25.737 56.7,20.044 "
                      ></polygon>
                    </svg>
                  </Button>
                </Form>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="copyright">
        <div className="copyright-text">
          <p>
            MADE WITH KNOWLEDGE FOR <a href="#!">© Saarthy Technosys</a>
          </p>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
