import React from "react";
import Banner from "../components/pastProject/banner";
import Intro from "../components/pastProject/intro";

const PastProject = () => {
  return (
    <div className="pastProject__wrapper">
      <Banner />
      <div className="container">
        <Intro />
      </div>
    </div>
  );
};

export default PastProject;
