import React from 'react';

const BrandsLogo = () => {
    return (
        <section className='brands-logo'>
            <div className="container">
                <div className="main-title text-center"><h2>Brands at central bussiness hub</h2></div>
                <div className="brands-grid">
                    <div className="brands-img">
                        <img src='/assets/logo-2.webp' alt='Tasva' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-4.webp' alt='Louis Phillipe' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-3.webp' alt='F-studio' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-1.webp' alt='Vanheusen' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-5.webp' alt='Allensolly' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-6.webp' alt='Arrow' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-7.webp' alt='Peter England' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-8.webp' alt='US polo Assn' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-9.webp' alt='Indian Terrain' />
                    </div>
                    <div className="brands-img">
                        <img src='/assets/logo-10.webp' alt='Blackberrys' />
                    </div>                
                    
                </div>
            </div>
        </section>
    )
}

export default BrandsLogo
