import React from 'react'

const FloorDetails = () => {
    return (
        <div className='floor-details container-fluid'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-lg-5 floor-plans align-self-center'>
                        <h3>DISPLAY YOUR FLOOR PLANS AND APARTMENT LAYOUTS</h3>
                        <p>At vero eos et accus amus et iusto odio</p>
                        <h1>First Floor</h1>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-7 floor-plans-images'>
                        <img src='/assets/floor-details/apart-slider-img-1.png' alt='#' />
                    </div>
                </div>
                <div className='floor-detail'>
                    <div className=' row'>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ENTRANCE</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>AREA, M2</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>BATHROMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ROOMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>PARKING</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>TERRACE</p>
                            <span>2</span>
                        </div>
                    </div>
                </div>
                <div className='row even'>
                    <div className='col-12 col-sm-6 col-lg-5 floor-plans align-self-center'>
                        <h3>DISPLAY YOUR FLOOR PLANS AND APARTMENT LAYOUTS</h3>
                        <p>At vero eos et accus amus et iusto odio</p>
                        <h1>Secound Floor</h1>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-7 floor-plans-images'>
                        <img src='/assets/floor-details/apart-slider-img-1.png' alt='#' />
                    </div>
                </div>
                <div className='floor-detail'>
                    <div className=' row'>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ENTRANCE</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>AREA, M2</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>BATHROMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ROOMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>PARKING</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>TERRACE</p>
                            <span>2</span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-lg-5 floor-plans align-self-center'>
                        <h3>DISPLAY YOUR FLOOR PLANS AND APARTMENT LAYOUTS</h3>
                        <p>At vero eos et accus amus et iusto odio</p>
                        <h1>Third Floor</h1>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-7 floor-plans-images'>
                        <img src='/assets/floor-details/apart-slider-img-1.png' alt='#' />
                    </div>
                </div>
                <div className='floor-detail'>
                    <div className=' row'>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ENTRANCE</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>AREA, M2</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>BATHROMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ROOMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>PARKING</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>TERRACE</p>
                            <span>2</span>
                        </div>
                    </div>
                </div>
                <div className='row even'>
                    <div className='col-12 col-sm-6 col-lg-5 floor-plans align-self-center'>
                        <h3>DISPLAY YOUR FLOOR PLANS AND APARTMENT LAYOUTS</h3>
                        <p>At vero eos et accus amus et iusto odio</p>
                        <h1>Fourth Floor</h1>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-7 floor-plans-images'>
                        <img src='/assets/floor-details/apart-slider-img-1.png' alt='#' />
                    </div>
                </div>
                <div className='floor-detail'>
                    <div className=' row'>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ENTRANCE</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>AREA, M2</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>BATHROMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>ROOMS</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>PARKING</p>
                            <span>2</span>
                        </div>
                        <div className='col-6 col-sm-2 area-detail'>
                            <p>TERRACE</p>
                            <span>2</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default FloorDetails
