import React, { useEffect, useState, useCallback } from "react";
import { Accordion, Card } from 'react-bootstrap';
import Slider from "react-slick";
import gsap from "gsap";
import ImageViewer from 'react-simple-image-viewer';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
gsap.registerPlugin(ScrollTrigger);


const Cbh = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        '/assets/gallery/1.webp',
        '/assets/gallery/2.webp',
        '/assets/gallery/3.webp',
        '/assets/gallery/4.webp',
        '/assets/gallery/5.webp',
        '/assets/gallery/6.webp',
        '/assets/gallery/7.webp',
        '/assets/gallery/8.webp',
        '/assets/gallery/9.webp',
    ];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const [activeId, setActiveId] = useState('0');

    function toggleActive(id) {
        if (activeId !== id) {
            setActiveId(id);
        }
        console.log(activeId)
    }
    useEffect(() => {

    }, [])
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className='main-cbh-banner'>
                <div className='main-video-block'>
                    <div className='container'>
                        <div className="bg-video-wrap youtube-video">
                            {/* <video src={BgVideo} loop muted autoPlay></video> */}
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://www.youtube.com/embed/AcuUWPvftGQ"
                                    frameBorder="0"
                                    loop
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="video"
                                    className="iframe-video"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-brochure">
                    {/* <a href="/assets/brochure.pdf" title="Download Brochure" download>Download Brochure</a> */}
                    <a href="/assets/brochure.pdf" title="Download Brochure" download>Download Brochure</a>
                </div>
            </div>
            <div className='amenities-details-block container-fluid'>
                <div className='container'>
                    <div className="section-header">
                        <h3>AMENITIES</h3>
                    </div>
                    <div className="amenities-grid-block">
                        <div className="inner-item">
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/location.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>LOCATION</h5>
                                    <ul className="dots-list">
                                        <li>Placed in most premium part of the city</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/elevators.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>ELEVATORS</h5>
                                    <ul className="dots-list">
                                        <li>Dedicated one elevator for showrooms</li>
                                        <li>Dedicated four elevators for office</li>
                                        <li>Dedicated two elevators for office owners with secured access</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/conference-room.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>CONFERENCE ROOM</h5>
                                    <ul className="dots-list">
                                        <li>With projector video conference facility</li>
                                        <li>(*chargeable)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/exquisite-lounge-area.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>EXQUISITE LOUNGE AREA</h5>
                                    <ul className="dots-list">
                                        <li>With magazine library</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/ample-parking.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>AMPLE PARKING</h5>
                                    <ul className="dots-list">
                                        <li>Four levels of parking</li>
                                        <li>Car parking management system</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/atm.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>ATM</h5>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/grand-foyers.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>GRAND FOYERS</h5>
                                    <ul className="dots-list">
                                        <li>Separate entry foyer for showrooms</li>
                                        <li>Double heighted office reception</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/escalators.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>ESCALATORS</h5>
                                    <ul className="dots-list">
                                        <li>Two escalators for showrooms</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/cafeteria.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>CAFETERIA</h5>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/sky-deck.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>SKY DECK</h5>
                                    <ul className="dots-list">
                                        <li>Open to sky lounge</li>
                                        <li>Rooftop walkway</li>
                                        <li>Beautiful landscaping</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/facilities-for-chauffeur.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>FACILITIES FOR CHAUFFEUR</h5>
                                    <ul className="dots-list">
                                        <li>Lounge & waiting area with television</li>
                                        <li>Dedicated toilets</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/cending-machines.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>VENDING MACHINES</h5>
                                    <ul className="dots-list">
                                        <li>For 24*7 tea/coffee & snacks ATM</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="inner-item">
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/illuminated-signage.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>ILLUMINATED SIGNAGE</h5>
                                    <ul className="dots-list">
                                        <li>For all showrooms and offices</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/building-management-system.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>BUILDING MANAGEMENT SYSTEM</h5>
                                    <ul className="dots-list">
                                        <li>For energy saving, operation of elevators, fire pumps and parking</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/wifi-facility.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>WIFI FACILITY</h5>
                                    <ul className="dots-list">
                                        <li>In dedicated areas</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/security.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>24 X 7 SECURITY</h5>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/pantry.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>PANTRY</h5>
                                    <ul className="dots-list">
                                        <li>In each office with water connection</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/professional-consultants.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>PROFESSIONAL CONSULTANTS</h5>
                                    <ul className="dots-list">
                                        <li>Elevation and lighting</li>
                                        <li>Landscaping</li>
                                        <li>Parking management</li>
                                        <li>Building maintenance</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/central-services-card.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>CENTRAL SERVICES CARD</h5>
                                    <ul className="dots-list">
                                        <li>For use in common services like vending</li>
                                        <li>machines, cafeteria and others</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/mobile-application.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>MOBILE APPLICATION</h5>
                                    <ul className="dots-list">
                                        <li>For owner’s assistance</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/power-backup.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>DG POWER BACKUP</h5>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/fire-fighting-systems.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>FIRE FIGHTING SYSTEMS</h5>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/common-toilets.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>COMMON TOILETS</h5>
                                    <ul className="dots-list">
                                        <li>On each floor with adequate cleaning and maintenance staff</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-item">
                                <div className="card-image">
                                    <img src="/assets/amenities/dedicated-concierge.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <h5>DEDICATED CONCIERGE</h5>
                                    <ul className="dots-list">
                                        <li>Like plumber, carpenter, electrician and all service based needs</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gallery-details-block container-fluid'>
                <div className='container'>
                    <div className="section-header">
                        <h3>Gallery</h3>
                    </div>
                    <div className="gallery-slider">
                        <Slider {...settings}>
                            {images.map((src, index) => (
                                <div key={index} className="image-item">
                                    <img
                                        src={src}
                                        onClick={() => openImageViewer(index)}
                                    />
                                </div>
                            ))}
                        </Slider>
                        {isViewerOpen && (
                            <div className="view-image-block">
                                <ImageViewer
                                    src={images}
                                    currentIndex={currentImage}
                                    disableScroll={false}
                                    closeOnClickOutside={true}
                                    onClose={closeImageViewer}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='floor-details container-fluid'>
                <div className='container'>
                    <div className="section-header">
                        <h3>Property Floor Plans</h3>
                    </div>
                    <Accordion defaultActiveKey='a'>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 order-2 order-md-1">
                                <Accordion.Collapse eventKey="a">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/basement.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="c">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/site-layout.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="b">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/groung-floor.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="1">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-1.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="2">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-2.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="3">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-3.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="5">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-5.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="6">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-6.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="7">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-7.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="8">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-8.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="9">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-9.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="10">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-10.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="11">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-11.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="12">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/floor-12.webp' alt='#' />
                                            </div>
                                        </div>
                                    </>
                                </Accordion.Collapse>
                                <Accordion.Collapse eventKey="d">
                                    <>
                                        <div className='row'>
                                            {/* <div className='col-12 col-sm-6 col-lg-4 floor-plans '>
                                        <div className='floor-detail'>
                                            <div className=' row'>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ENTRANCE</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>AREA, M2</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>BATHROMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>ROOMS</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>PARKING</p>
                                                    <span>2</span>
                                                </div>
                                                <div className='col-6 col-md-6 area-detail'>
                                                    <p>TERRACE</p>
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className='col-12 floor-plans-images'>
                                                <img src='/assets/cbh-floor-details/sky-deck.webp' alt='#' />
                                            </div>
                                        </div>

                                    </>
                                </Accordion.Collapse>
                            </div>
                            <div className="col-md-6 col-lg-8 order-1 order-md-2">
                                <div className='floor-tabs'>
                                    <Accordion.Toggle onClick={() => toggleActive('a')} className={activeId === 'a' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="a">
                                        <span>Basement</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('c')} className={activeId === 'c' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="c">
                                        <span>Site Layout</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('b')} className={activeId === 'b' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="b">
                                        <span>Ground Floor</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('1')} className={activeId === '1' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="1">
                                        <span>Floor 1</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('2')} className={activeId === '2' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="2">
                                        <span>Floor 2</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('3')} className={activeId === '3' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="3">
                                        <span>Floor 3 & 4</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    {/* <Accordion.Toggle onClick={() => toggleActive('3')} className={activeId === '3' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="4">
                                        <span>Floor 4</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle> */}
                                    <Accordion.Toggle onClick={() => toggleActive('5')} className={activeId === '5' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="5">
                                        <span>Floor 5</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('6')} className={activeId === '6' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="6">
                                        <span>Floor 6</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('7')} className={activeId === '7' ? 'custom-toggle active' : 'custom-toggle hide'} as={Card.Header} eventKey="7">
                                        <span>Floor 7</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('8')} className={activeId === '8' ? 'custom-toggle active' : 'custom-toggle hide'} as={Card.Header} eventKey="8">
                                        <span>Floor 8</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('9')} className={activeId === '9' ? 'custom-toggle active' : 'custom-toggle hide'} as={Card.Header} eventKey="9">
                                        <span>Floor 9</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('10')} className={activeId === '10' ? 'custom-toggle active' : 'custom-toggle hide'} as={Card.Header} eventKey="10">
                                        <span>Floor 10</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('11')} className={activeId === '11' ? 'custom-toggle active' : 'custom-toggle hide'} as={Card.Header} eventKey="11">
                                        <span>Floor 11</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('12')} className={activeId === '12' ? 'custom-toggle active' : 'custom-toggle hide'} as={Card.Header} eventKey="12">
                                        <span>Floor 12</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                    <Accordion.Toggle onClick={() => toggleActive('d')} className={activeId === 'd' ? 'custom-toggle active' : 'custom-toggle'} as={Card.Header} eventKey="d">
                                        <span>Sky Deck</span>
                                        <img src='/assets/floor-details/floor.png' alt='#' width='30' />
                                    </Accordion.Toggle>
                                </div>
                                <div className="download-brochure-btn">
                                    <a href="/assets/CBH-Floor-Plans.pdf" title="Download Floor Plan" download>Download Floor Plan</a>
                                </div>
                            </div>
                        </div>
                    </Accordion>
                </div>
            </div>
        </>
    )
};

export default Cbh;
