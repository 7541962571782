import React from "react";
// import OurTeamContact from "../components/contactUs/OurTeamContact";
import ContactForm from "../components/contactUs/contactForm";

const ContactUs = () => {
  return (
    <div className="contactUs__wrapper">
      <ContactForm />
      {/* <OurTeamContact /> */}
    </div>
  );
};

export default ContactUs;
