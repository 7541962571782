import React from 'react';

import BgVideo from "../../assets/central-bazar.mp4";

const YoutubeVideo = () => {
    return (
        <section className='Main-video'>
             <div className="bg-video-wrap">
                <video src={BgVideo} loop muted autoPlay>
                </video>
            </div>
        </section>
    )
}

export default YoutubeVideo
