import React from "react";

const Banner = () => {
  return (
    <div>
      <div className="banner__wrapper">
        <div className="banner__detail">
          <span className="pastProject__banner__title">Find the Best</span>
          <span className="pastProject__banner__subTitle">
            MORDERN APPARTMENT IN
          </span>
          <span className="pastProject__banner__subTitle">
            A NEW RESIDENTIAL COMPLEX
          </span>
          <button className="pastProject__banner__button">LEARN MORE</button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
