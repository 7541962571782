import React, { useEffect } from "react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let tl = gsap.timeline();

const Interiors = () => {
  useEffect(() => {
    tl.from(".interiors__image", {
      opacity: 0,
      duration: 0,
      scrollTrigger: {
        trigger: ".interiors",
        start: "200px center",
        end: "500px center",
      },
    });

    gsap.from(".left-image-slider", {
      opacity: 0,
      scale: 1.2,
      ease: "expo.inOut",
      duration: 1.3,
      scrollTrigger: {
        trigger: ".interiors",
        start: "200px center",
        end: "600px center",

      },
    });

    gsap.from(".right-content-text", {
      opacity: 0,
      ease: "expo.inOut",
      duration: 1.3,
      delay: 2.3,
      scrollTrigger: {
        trigger: ".interiors",
        start: "200px center",
        end: "600px center",
      },
    });

    gsap.to(".bottom__animation__text", {
      x: 70,
      opacity: 1,
      scrollTrigger: {
        trigger: ".interiors",
        start: "400px center",
        end: "600px center",
      },
      stagger: {
        // wrap advanced options in an object
        each: 0.1,
        ease: "power2.inOut",
      },
    });
  }, []);

  return (
    <section className="interiors">
      <div className="container">
        <div className="row">
          <div className="col-md-6 left-image-slider">
            <img
              src="/assets/interiors.webp"
              alt="#"
              className="interiors__image"
            />
          </div>
          <div className="col-md-6 align-self-center">
            <div className="right-content-text">
              <h2>The Pinnacle of Prestige</h2>
              <p>Inspired by the ART DECO STYLE of early 20th Century Chicago and New York, the design harkens back to the ritz and glitz of the Roaring Twenties. The architecture incorporates modern elements in a broadly classical frame,blending functionality with practicality.
                {" "}
              </p>
              <p>Central Business Hub comprises one building with a total built-up area of 3,50,000 sq. ft.
                The new-age workspace is tastefully designed giving today’s generation essential amenities.
              </p>
              <div className="content-lists">
                <span><strong>Locations :</strong> Parle Point - The heart of the city</span>
                <span><strong> Architecture :</strong>  Inspired by the Art Deco Style of the early 20th century, Chicago and New York. Also known as the classic american architecture</span>
                <span><strong> Amenities :</strong> 4 Level Car Parking, 7 Elevators, 2 Escalators, Breathtaking Sky Deck, Conference Room, Cafeteria and much more. </span>
                <span><strong> Ample Parking :</strong> 4 Level Car Parking for 300 Cars and 500 Bikes with Parking Management System</span>
                <span><strong>Corporate environment :</strong> To maintain the corporate environment of the building we have restricted access to certain categories of businesses like cafes/restaurants, spa/salons, gym/fitness centers, cinemas and any other category that doesn’t fit with the corporate feel of the building. </span>
              </div>

              <div className="more-info">
                <Link className="more-info-btn" to="/cbh">
                  <span className="btn-icon">
                    <svg
                      x="0px"
                      width="10"
                      y="0px"
                      viewBox="0 0 5.5 10.1"
                      enableBackground="new 0 0 5.5 10.1"
                    >
                      <path
                        fill="#BBBBBB"
                        d="M1.3,5.5L1.2,5.2C2.1,4.5,2.7,4.1,3,3.9c0.3-0.2,0.6-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0.1S4.1,4,4.1,4.2
                                        c0,0.3-0.1,0.8-0.4,1.7L3.5,6.5c-0.3,1-0.4,1.6-0.4,1.9c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.4-0.2,1-0.6l0.2,0.3L3.2,9.1
                                        C2.8,9.3,2.6,9.4,2.4,9.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.3-0.1C1.7,9.3,1.7,9.2,1.7,8.9c0-0.3,0.1-0.7,0.2-1.1l0.4-1.4
                                        C2.4,5.7,2.5,5.3,2.5,5c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.3,0.1C1.8,5.1,1.5,5.3,1.3,5.5z M3.2,2c0-0.3,0.1-0.5,0.2-0.7
                                        S3.8,1,4,1c0.2,0,0.3,0.1,0.5,0.2s0.2,0.3,0.2,0.5c0,0.3-0.1,0.5-0.2,0.7C4.3,2.5,4.1,2.6,3.8,2.6c-0.2,0-0.4-0.1-0.5-0.2
                                        C3.2,2.3,3.2,2.1,3.2,2z"
                      ></path>
                    </svg>
                  </span>
                  <span>Know More</span></Link>
                <div className="download-brochure-btn">
                  <a href="/assets/brochure.pdf" title="Download Brochure" download>Download Brochure</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="bottom-right-shap"></div>
      <div className="bottom-animation-text">
        <span className="bottom__animation__text">I</span>
        <span className="bottom__animation__text">n</span>
        <span className="bottom__animation__text">t</span>
        <span className="bottom__animation__text">e</span>
        <span className="bottom__animation__text">r</span>
        <span className="bottom__animation__text">i</span>
        <span className="bottom__animation__text">o</span>
        <span className="bottom__animation__text">r</span>
        <span className="bottom__animation__text">s</span>
      </div>
    </section>
  );
};

export default Interiors;
