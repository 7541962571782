import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import * as Yup from 'yup';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const ContactForm = () => {
  const [loader, setLoader] = useState(false);
  const [res, setres] = useState({})
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaerror, setrecaptchaerror] = useState([])

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      message: ""
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("The full name field is required."),
      phone: Yup.string().max(12).required("The phone field is required."),
      email: Yup.string().email().required("The email field is required."),
      message: Yup.string().required("The message field is required.")
    }),
    onSubmit: async (values, { resetForm, setErrors }) => {
      setLoader(true);
      // console.log(values);
      let token = "";
      try {
        if (executeRecaptcha) {
          token = await executeRecaptcha();
        }
        else {
          alert("token");
          setres({
            message: "Could not load Recaptcha",
            success: false
          })
        }

      } catch (error) {
        // console.log(error?.message)
        if (error?.message?.includes("Invalid site key or not loaded in api.js")) {
          setres({
            message: "Invalid site key or not loaded in api.js",
            success: false
          })
        }
        else {
          setres({
            message: "Could not verify Recaptcha",
            success: false
          })
        }
      }
      // console.log("token=", token);
      if (token === "") {
        return;
      }

      await axios.post('/contact-us',
        { ...values, recaptcha: token },
        {
          headers: { "Content-Type": "application/json" }
        }).then((response) => {
          // console.log("kkkk", response);
          if (response) setLoader(false);
          if (response.status === 200) {
            setTimeout(() => setres(" "), 5000);
            setres(response.data)
            resetForm()
          }
        }).catch((response) => {
          // console.log("kkkk", response);
          if (response) setLoader(false);
          if (response?.response) {
            if (response?.response.status === 400) {
              setTimeout(() => setrecaptchaerror(" "), 5000);
              setErrors(response?.response?.data?.response)
              // console.log("error", response?.response);
              if (response?.response.data?.response?.recaptcha) {
                // console.log("error", response?.response.data?.response?.recaptcha);
                setrecaptchaerror(response?.response.data?.response?.recaptcha)
              }
            }
            else {
              setres({
                message: "There has been error.",
                success: false
              });
              // setTimeout(() => setres(" "), 5000);
            }
          }

        })
    },
    // onSubmit: async (values, { resetForm, setErrors }) => {
    //   setLoader(true);
    //   console.log(values);
    //   let token = "";
    //   try {
    //     if (executeRecaptcha) {
    //       token = await executeRecaptcha();
    //     }
    //     else {
    //       alert("token");
    //       setres({
    //         message: "Could not load Recaptcha",
    //         success: false
    //       })
    //     }

    //   } catch (error) {
    //     console.log(error?.message)
    //     if (error?.message?.includes("Invalid site key or not loaded in api.js")) {
    //       setres({
    //         message: "Invalid site key or not loaded in api.js",
    //         success: false
    //       })
    //     }
    //     else {
    //       setres({
    //         message: "Could not verify Recaptcha",
    //         success: false
    //       })
    //     }
    //   }
    //   console.log("token=", token);
    //   if (token === "") {
    //     return;
    //   }

    //   const response = await axios.post('/contact-us',
    //     { ...values, recaptcha: token },
    //     {
    //       headers: { "Content-Type": "application/json" }
    //     })
    //   console.log(response);
    //   if (response) setLoader(false);
    //   if (response.status === 200) {
    //     setTimeout(() => setres(" "), 9000);
    //     setres(response.data)
    //     resetForm()
    //   }
    //   else
    //     if (response.status === 400) {
    //       setErrors(response.data.response)
    //     }
    //     else {
    //       setres({
    //         message: "There has been error.",
    //         success: false
    //       });
    //     }
    // },
  })

  return (
    <div>
      <div className="map__wrapper"></div>
      <div className="contact__form__wrapper">
        <div className="background__image__contact">
          <div className="contact__form__main">
            <div className="contact__form__title">
              <h2>REQUEST A VISIT</h2>
            </div>
            <form className="contact__form__data" onSubmit={formik.handleSubmit}>
              <div className="contact__form__data__wrapper">
                <div className="fullName__wrapper">

                  {res?.message && <p className={res?.success ? "text-success" : "text-danger"}>{res?.message}</p>}
                  {recaptchaerror[0] && <p className="text-danger">{recaptchaerror[0]}</p>}

                  <input
                    // required
                    // onChange={handleChange}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.fullName}
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    className="contact__fullName"
                  />
                  {formik.errors?.fullName && formik.touched?.fullName && <p className="text-danger">{formik.errors?.fullName}</p>}
                </div>

                <div className="fullName__wrapper">
                  <input
                    // required
                    type="email"
                    placeholder="Email"
                    value={formik?.values?.email}
                    name="email"
                    className="contact__fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors?.email && formik.touched?.email && <p className="text-danger">{formik.errors?.email}</p>}
                </div>

                <div className="fullName__wrapper">
                  <input
                    // required
                    type="number"
                    value={formik?.values?.phone}
                    placeholder="phone number"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="contact__fullName"
                  />
                  {formik.errors?.phone && formik.touched?.phone && <p className="text-danger">{formik.errors?.phone}</p>}
                </div>

                {/* <div className="halfWidth__field right__padding">
                  <input
                    type="date"
                    placeholder="New Date"
                    className="contact__date"
                  />
                </div>
                <div className="halfWidth__field left__padding">
                  <select
                    id="cars"
                    name="contact__time"
                    className="contact__time"
                  >
                    <option value="volvo" defaultValue>
                      Time
                    </option>
                    <option value="saab">11:30</option>
                    <option value="fiat">13:00</option>
                    <option value="audi">15:00</option>
                  </select>
                </div> */}

                <div className="fullName__wrapper">
                  <textarea
                    // required
                    cols="40"
                    rows="10"
                    name="message"
                    value={formik?.values?.message}
                    className="contact__message"
                    placeholder="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.errors?.message && formik.touched?.message && <p className="text-danger">{formik.errors?.message}</p>}
                </div>

                <div className="contact__submit__button__wrapper" >
                  <button className="contact__submit__button" type="submit" disabled={loader ? true : false} >
                    <span className="button__border">
                      <span className="top__border"></span>
                      <span className="bottom__border"></span>
                      <span className="right__border"></span>
                      <span className="left__border"></span>
                    </span>
                    <span className="button__name">{
                      loader && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ margin: 1, padding: 1 }}></span>
                    }{!loader ? "Contact" : " Loading.."}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
