import React, { useEffect, useRef } from "react";
import SampleImage from "../../assets/pastIntro.jpg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Intro = () => {
  const ref = useRef(null);

  useEffect(() => {
    let sections = gsap.utils.toArray(".panel");
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".image__horizontal",
        pin: ".horizontal__wrapper",
        scrub: 1,
        snap: 1 / (sections.length - 1),
        start: "top center-=200px",
        // markers: true,
      },
    });
  }, []);
  return (
    <div className="introPastProject__wrapper">
      <div ref={ref} className="mainIntro__wrapper">
        <div className="leftIntro__wrapper">
          <span className="leftIntro__title">Luxury Appartment</span>
          <h2>INTRODUCING A NEW RESIDENTIAL COMPLEX</h2>
          <div className="leftIntro__para">
            Each apartments has been individually designed to maximise space and
            light. Smart Home Technology installed as standard in each apartment
            putting you in total control of your home at the touch of a button
            from wherever you might be.
          </div>
        </div>
        <div className="rightIntro__wrapper">
          <div className="rightIntro__image"></div>
        </div>
      </div>

      <div className="introStats__wrapper">
        <div className="introStats__data">
          <span>136</span>
          <h6>Luxe Appartment</h6>
        </div>
        <div className="introStats__data">
          <span>355</span>
          <h6>Bedrooms</h6>
        </div>
        <div className="introStats__data">
          <span>326</span>
          <h6>Square Areas</h6>
        </div>
        <div className="introStats__data">
          <span>1225</span>
          <h6>Happy Customers</h6>
        </div>
      </div>
      <div className="horizontal__wrapper">
        <div className="image__horizontal">
          <div className="panel">
            <img src={SampleImage} alt="Slider Images" />
          </div>
          <div className="panel">
            <img src={SampleImage} alt="Slider Images" />
          </div>
          <div className="panel">
            <img src={SampleImage} alt="Slider Images" />
          </div>
          <div className="panel">
            <img src={SampleImage} alt="Slider Images" />
          </div>
          <div className="panel">
            <img src={SampleImage} alt="Slider Images" />
          </div>
          <div className="panel">
            <img src={SampleImage} alt="Slider Images" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
