import React, { useState } from "react";
import BannerHover from "../../assets/bannerHover.png";
import HoverPattern from "../../assets/hoverPattern.png";
import { Link } from "react-router-dom";


const floorData = {
  null: {
    title: "HOVER OVER BUILDING",
    sub: "",
    data: "",
  },
  ground: {
    title: "Ground FLOOR",
    sub: "11 Units",
    data: "Carpet Area :13,822",
  },
  one: {
    title: <span>1<sup>st</sup> FLOOR</span>,
    sub: "10 Units",
    data: "Carpet Area :12,543",
  },
  two: {
    title:<span>2<sup>nd</sup> FLOOR</span>,
    sub: "54 Units",
    data: "Carpet Area :10,028",
  },
  three: {
    title: <span>3<sup>rd</sup> & 4<sup>rd</sup> FLOOR (along with basement 1 and 2)</span>,
    sub: "300 cars and 500 bikes",
    data: "Carpet Area :13,822",
  },
  // four: {
  //   title: "4th FLOOR",
  //   sub: "4 APPARTMENT",
  //   data: "140ms - 200ms",
  // },
  five: {
    title: <span>5<sup>th</sup> FLOOR</span>,
    sub: "14 Units",
    data: "Carpet Area :16,834",
  },
  six: {
    title: <span>6<sup>th</sup> FLOOR</span>,
    sub: "16 Units",
    data: "Carpet Area :16,834",
  },
  seven: {
    title: <span>7<sup>th</sup> FLOOR</span>,
    sub: "16 Units",
    data: "Carpet Area :16,834",
  },
  eight: {
    title: <span>8<sup>th</sup> FLOOR</span>,
    sub: "15 Units",
    data: "Carpet Area :5,095",
  },
  nine: {
    title:<span>9<sup>th</sup> FLOOR</span>,
    sub: "15 Units",
    data: "Carpet Area :5,095",
  },
  ten: {
    title: <span>10<sup>th</sup> FLOOR</span>,
    sub: "15 Units",
    data: "Carpet Area :15,097",
  },
  eleven: {
    title: <span>11<sup>th</sup> FLOOR</span>,
    sub: "15 Units",
    data: "Carpet Area :11,794",
  },
  twelve: {
    title: <span>12<sup>th</sup> FLOOR</span>,
    sub: "15 Units",
    data: "Carpet Area :12,499",
  },
};

const Banner = () => {
  const [ floor, setFloor] = useState(null);

  function changeColor(el) {
    setFloor(el);
  }
  return (
    <div>
      <div className="banner__hover__wrapper">
        <img
          src={BannerHover}
          alt="Main Banner"
          className="main__hover__img"
        ></img>
        <svg viewBox="0 0 1920 1080" className="svg__wrapper">
        <Link to="/cbh">
        <defs>
         
        <pattern
          id="img1"
          patternUnits="userSpaceOnUse"
          width="100"
          height="100"
        >
          <image href={HoverPattern} x="0" y="0" width="100" height="100" />
        </pattern>
      </defs>
         
          <polygon
            onMouseOver={() => changeColor("twelve")}
            className="polygon__twelve"
            points="655,90 655,130 940,95 1392,146 1392,105 940,40"
            style={
              floor === "twelve"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("eleven")}
            className="polygon__eleven"
            points="655,131 655,160 940,132 1392,172 1392,148 941,96"
            style={
              floor === "eleven"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("ten")}
            className="polygon__ten"
            points="629,173 629,215 940,199 1402,220 1402,183 941,146"
            style={
              floor === "ten"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("nine")}
            className="polygon__nine"
            points="629,215 629,258 940,255 1402,260 1402,220 941,199"
            style={
              floor === "nine"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("eight")}
            className="polygon__eight"
            points="629,259 629,293 940,304 1402,291 1402,261 941,256"
            style={
              floor === "eight"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("seven")}
            className="polygon__seven"
            points="604,302 604,343 940,372 1427,337 1427,301 941,316"
            style={
              floor === "seven"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("six")}
            className="polygon__five"
            points="604,344 604,384 940,432 1427,377 1427,338 941,373"
            style={
              floor === "six"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 0 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("five")}
            className="polygon__five"
            points="604,385 604,434 907,490 1107,460 1127,460 1427,417 1427,378 941,433"
            style={
              floor === "five"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 1 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("three")}
            className="polygon__three"
            points="586,445 586,519 903,609 1107,560 1127,560 1250,535 1257,539 1467,490 1467,423 1257,457 1250,452 1127,470 1107,467 903,499"
            style={
              floor === "three"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 1 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("two")}
            className="polygon__two"
            points="589,539 589,565 888,660 1107,605 1127,610 1252,583 1257,584 1467,532 1467,504 1257,547 1250,549 1127,577 1107,575 903,625"
            style={
              floor === "two"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 1 }
            }
          />

          <polygon
            onMouseOver={() => changeColor("one")}
            className="polygon__one"
            points="589,566 589,625 888,730 1107,665 1127,670 1252,640 1257,642 1467,582 1467,544 1257,600 1250,595 1127,627 1107,612 885,665"
            style={
              floor === "one"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 1 }
            }
          />
          <polygon
            onMouseOver={() => changeColor("ground")}
            className="polygon__ground"
            points="589,626 589,675 888,800 1095,725 1127,740 1252,698 1257,698 1467,632 1467,580 1257,640 1250,638 1127,677 1107,672 885,735"
            style={
              floor === "ground"
                ? { fill : 'url(#img1)', opacity : 1 }
                : { fill: "transparent", opacity: 1 }
            }
          />
          </Link>
        </svg>
        <div className="floor__data__wrapper" key={floor}>
          <h5 className="floor__data__title">{floorData[floor].title}</h5>
          <h6 className="floor__data__subData">{floorData[floor].sub}</h6>
          <p className="floor__data__sub">{floorData[floor].data}</p>
        </div>
      </div>

    </div>
  );
};

export default Banner;
