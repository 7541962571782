import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages import

import AboutUs from "./pages/about";
import Home from "./pages/home";
import Cbh from "./pages/cbh";
import PastProject from "./pages/pastProject";
import ContactUs from "./pages/contactUs";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import FloorDetails from "./pages/FloorDetails";
import PastProjectList from './pages/PastProjectList'
import CbhProjectStatus from './pages/CbhProjectStatus'
// components
import ScrollToTop from "./components/ScrollToTop";
import axios from "axios";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const App = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API;

    const loader = setTimeout(() => {
      setIsLoading(false)
    }, 1000);
    return () => clearTimeout(loader)
  }, [])

  return (
    <div>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} >
        <Router>
          {isLoading ?
            <div className="loading-block">
              <img src="/assets/cbh-logo.png" alt="" />
            </div>
            :
            <>
              <ScrollToTop />
              <Navbar />
              <Switch>
                <>
                  <div className="main__body">
                    <Route exact path="/" component={Home} />
                    <Route exact path="/cbh" component={Cbh} />
                    <Route exact path="/past-project-list" component={PastProjectList} />
                    <Route exact path="/past-project" component={PastProject} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route path="/about" component={AboutUs} />
                    <Route path="/floor-details" component={FloorDetails} />
                    <Route path='/cbh-project-status' component={CbhProjectStatus} />
                  </div>
                </>
              </Switch>
              <Footer />
            </>
          }
        </Router>
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default App;
