import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Form, Button, FormCheck } from "react-bootstrap";
import Logo from "../assets/cbh-logo.png";
import { useFormik } from "formik";
import * as Yup from 'yup'
import axios from "axios";
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Navbar = ({ history }) => {
  const [navbar, setNavbar] = useState(false);
  const [enquiry, setEnquiry] = useState(false);
  const [recaptchaerror, setrecaptchaerror] = useState([])
  const { executeRecaptcha } = useGoogleReCaptcha();
  // console.log(recaptchaerror[0]);

  useEffect(() => {
    // check if url changes then set navbar to false, history is called with react router dom
    history.listen(() => {
      setNavbar(false);
      setEnquiry(false);
    });
  });

  const [loader, setLoader] = useState(false);
  const [res, setres] = useState({})

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("The full name field is required."),
      phone: Yup.string().max(12).required("The phone field is required."),
      email: Yup.string().email().required("The email field is required."),
      message: Yup.string().required("The message field is required."),
    }),

    onSubmit: async (values, { resetForm, setErrors }) => {
      setLoader(true);
      // console.log(values);
      let token = "";
      try {
        if (executeRecaptcha) {
          token = await executeRecaptcha();
          // console.log("token===",token);
        }
        else {
          setres({
            message: "Could not load Recaptcha",
            success: false
          })
        }

      } catch (error) {
        console.log(error?.message)
        if (error?.message?.includes("Invalid site key or not loaded in api.js")) {
          setres({
            message: "Invalid site key or not loaded in api.js",
            success: false
          })
        }
        else {
          setres({
            message: "Could not verify Recaptcha",
            success: false
          })
        }
      }
      // console.log("token=", token);
      if (token === "") {
        return;
      }

      await axios.post('/contact-us',
        { ...values, recaptcha: token },
        {
          headers: { "Content-Type": "application/json" }
        }).then((response) => {
          // console.log("kkkk", response);
          if (response) setLoader(false);
          if (response.status === 200) {
            setTimeout(() => setres(" "), 5000);
            setres(response.data)
            resetForm()
          }
        }).catch((response) => {
          // console.log("kkkk", response);
          if (response) setLoader(false);
          if (response?.response) {
            if (response?.response.status === 400) {
              setTimeout(() => setrecaptchaerror(" "), 5000);
              setErrors(response?.response?.data?.response)
              // console.log("error", response?.response);
              if (response?.response.data?.response?.recaptcha) {
                // console.log("error", response?.response.data?.response?.recaptcha);
                setrecaptchaerror(response?.response.data?.response?.recaptcha)
              }
            }
            else {
              setres({
                message: "There has been error.",
                success: false
              });
            }
          }

        })

      // if (response) setLoader(false);
      // if (response.status === 200) {
      //   setTimeout(() => setres(" "), 9000);
      //   setres(response.data)
      //   resetForm()
      // }
      // else
      //   if (response.status === 400) {
      //     setErrors(response.data.response)
      //     console.log("error",response);
      //     if(response?.recaptcha)
      //     {
      //       console.log("error");
      //     }
      //   }
      //   else {
      //     setres({
      //       message: "There has been error.",
      //       success: false
      //     });
      //   }
    },
  })

  async function onChange(e) {
    try {
      if (e.target.checked) {
        if (executeRecaptcha) {
          const token = await executeRecaptcha();
          formik.setFieldValue("recaptcha", token)
          formik.setFieldError("recaptcha", false)
        }
        else {
          if (e.preventDefault) {
            e.preventDefault();
          }
          e.returnValue = false;
          formik.setFieldError("recaptcha", "Could not Verify Recaptcha")
        }
      }
      else {
        formik.setFieldValue("recaptcha", "")
        // formik.setFieldError("recaptcha",false)
      }

    } catch (error) {
      console.log(error?.message)
      if (error?.message?.includes("Invalid site key or not loaded in api.js")) {
        formik.setFieldError("recaptcha", "Invalid site key or not loaded in api.js")
      }
      else {
        formik.setFieldError("recaptcha", "Could not Verify Recaptcha")
      }
      if (e.preventDefault) {
        e.preventDefault();
      }
      e.returnValue = false;
    }
  };

  return (
    <>
      <div
        className={
          enquiry ? "enquiryForm__wrapper active" : "enquiryForm__wrapper"
        }
      >
        <div
          className={
            enquiry ? "hamburgerEnquiry__icon active" : "hamburgerEnquiry__icon"
          }
          onClick={() => setEnquiry(!enquiry)}
        >
          <div className="hamEnquiry__adjust">
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="enquiry-form-wrapper">

          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              {res?.message && <p className={res?.success ? "text-success" : "text-danger"}>{res?.message}</p>}
              {recaptchaerror[0] && <p className="text-danger">{recaptchaerror[0]}</p>}


              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter name"
              />
            </Form.Group>
            {formik.errors?.fullName && formik.touched?.fullName && <p className="text-danger">{formik.errors?.fullName}</p>}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik?.values?.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter email"
              />
            </Form.Group>
            {formik.errors?.email && formik.touched?.email && <p className="text-danger">{formik.errors?.email}</p>}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>phone No</Form.Label>
              <Form.Control
                type="number"
                name="phone"
                value={formik?.values?.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter phone no"
              />
            </Form.Group>
            {formik.errors?.phone && formik.touched?.phone && <p className="text-danger">{formik.errors?.phone}</p>}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Leave Your Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formik?.values?.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="message"
                placeholder="Enter Your Message"
              />
            </Form.Group>
            {formik.errors?.message && formik.touched?.message && <p className="text-danger">{formik.errors?.message}</p>}

            {/* <div id="recaptcha" style={{ display: "flex", flexDirection: "row" }}>
              <FormCheck name="recaptcha" onChange={onChange} checked={Boolean(formik.values.recaptcha)} onBlur={formik.handleBlur} />
              <label>Verify ReCaptcha</label>
            </div>
            {formik.errors?.recaptcha && formik.touched?.recaptcha && <p className="text-danger">{formik.errors?.recaptcha}</p>} */}

            <center>
              <Button variant="outline-success" type="submit" style={{ marginTop: 15 }} disabled={loader ? true : false}>
                {
                  loader && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ margin: 1, padding: 1 }} />
                }
                {!loader ? "Submit" : " Loading.."}
              </Button>

            </center>
          </Form>
        </div>
      </div>
      <div className={navbar ? "nav__wrapper active" : "nav__wrapper"}>
        <div className="menu__wrapper">
          <div className="logo__full">
            <Link to="/">
              <img src={Logo} alt="" className="mx-auto" />
              <span>Central Business Hub</span>
            </Link>
          </div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/cbh">CBH</Link>
              </li>
              <li>
                <Link to='/cbh-project-status'>Project Status</Link>
              </li>
              <li>
                <Link to="/about">about</Link>
              </li>
              {/* <li>
                  <Link to="/past-project-list">Past Projects List</Link>
               </li> */}
              <li>
                <Link to="/past-project-list">Past Projects</Link>
              </li>
              <li>
                <Link to="/contact">Get in Touch</Link>
              </li>
            </ul>
          </nav>
          <div className="download-call-btn">
            <div className="download-brochure-btn">
              <a href="./assets/brochure.pdf" download>Download Brochure</a>
            </div>
            <div className="call-now-btn">
              <a href="tel:+89888 65888">Call Now</a>
            </div>
          </div>
          <div className="menu__footer">
          </div>
        </div>
        {/* hamburger Icon */}
        <div
          className={
            navbar
              ? "hamburger__icon hamburger__icon_active"
              : "hamburger__icon"
          }
          onClick={() => setNavbar(!navbar)}
        >
          <div className="ham__adjust">
            <span></span>
            <span></span>
          </div>
        </div>
        {/* Hamburger Icon End */}

        <div className="logo__wrapper">
          <Link to="/"><img src={Logo} alt="" className="mx-auto px-2" /></Link>
          {/* <img src={Logo} alt="" /> */}
        </div>

        <div className="enquiry__wrapper" onClick={() => setEnquiry(!enquiry)}>
          <span>Make an Enquiry</span>
        </div>
      </div>

    </>
  );
};

export default withRouter(Navbar);
