import React, { useEffect } from "react";
// import React from "react";
import gsap from "gsap";
import { Link } from "react-router-dom";

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// let tl = gsap.timeline();

const Building = () => {
  useEffect(() => {
    gsap.from(".building", {
      scrollTrigger: {
        trigger: ".building",

        start: "top center",
      },
      duration: 0.7,
      opacity: 0,
      x: 50,
    });
    gsap.from(".building__animation__span", {
      x: -70,
      opacity: 0,
      scrollTrigger: {
        trigger: ".building",

        start: "top center",
      },
      stagger: {
        // wrap advanced options in an object
        each: 0.1,
        ease: "power2.inOut",
      },
    });

    gsap.from(".building__imageOne__wrapper", {
      scrollTrigger: {
        trigger: ".anim-two-img",
        // markers: true,
        start: "top center",
      },
      duration: 1,
      height: 0,
    });

    gsap.to(".building__imageTwo__wrapper", {
      scrollTrigger: {
        trigger: ".anim-two-img",

        start: "top center",
      },
      duration: 1.5,
      height: "100%",
    });

    gsap.from(".building__content__title", {
      scrollTrigger: {
        trigger: ".anim-two-img",

        start: "top center",
      },

      duration: 0.7,
      x: -40,
      autoAlpha: 0,
    });

    gsap.from(".building__content__listing", {
      scrollTrigger: {
        trigger: ".anim-two-img",

        start: "top center",
      },
      stagger: 0.2,
      duration: 0.7,
      x: -40,
      autoAlpha: 0,
    });
  });
  return (
    <section className="building">
      <div className="top-left-background-corner"></div>
      <div className="section-top-text">
        <span className="building__animation__span">b</span>
        <span className="building__animation__span">u</span>
        <span className="building__animation__span">i</span>
        <span className="building__animation__span">l</span>
        <span className="building__animation__span">d</span>
        <span className="building__animation__span">i</span>
        <span className="building__animation__span">n</span>
        <span className="building__animation__span">g</span>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="building-wrapper">
              <img src="/assets/office-foyer.webp" alt=""/>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="building-title-sub-content">
              <h2 className="building__content__title">Specification</h2>
              <ul>
                <li className="building__content__listing">
                  <label>PROPERTY SIZE:</label>
                  <h5>3,50,000 Sq. Ft.</h5>
                </li>
                <li className="building__content__listing">
                  <label>Type of project:</label>
                  <h5>Premium High Street</h5>
                </li>
                <li className="building__content__listing">
                  <label>Average floor plate area:</label>
                  <h5>13750  Sq. Ft. Each floor</h5>
                </li>
              </ul>
              <div className="more-info">
                <Link className="more-info-btn" to="/cbh">
                <span className="btn-icon">
                    <svg
                      x="0px"
                      width="10"
                      y="0px"
                      viewBox="0 0 5.5 10.1"
                      enableBackground="new 0 0 5.5 10.1"
                    >
                      <path
                        fill="#BBBBBB"
                        d="M1.3,5.5L1.2,5.2C2.1,4.5,2.7,4.1,3,3.9c0.3-0.2,0.6-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0.1S4.1,4,4.1,4.2
                                        c0,0.3-0.1,0.8-0.4,1.7L3.5,6.5c-0.3,1-0.4,1.6-0.4,1.9c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.4-0.2,1-0.6l0.2,0.3L3.2,9.1
                                        C2.8,9.3,2.6,9.4,2.4,9.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.3-0.1C1.7,9.3,1.7,9.2,1.7,8.9c0-0.3,0.1-0.7,0.2-1.1l0.4-1.4
                                        C2.4,5.7,2.5,5.3,2.5,5c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.3,0.1C1.8,5.1,1.5,5.3,1.3,5.5z M3.2,2c0-0.3,0.1-0.5,0.2-0.7
                                        S3.8,1,4,1c0.2,0,0.3,0.1,0.5,0.2s0.2,0.3,0.2,0.5c0,0.3-0.1,0.5-0.2,0.7C4.3,2.5,4.1,2.6,3.8,2.6c-0.2,0-0.4-0.1-0.5-0.2
                                        C3.2,2.3,3.2,2.1,3.2,2z"
                      ></path>
                    </svg>
                  </span>
                  <span>Know More</span></Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Building;
