import React ,{useEffect} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Logo from "../../assets/cbh-logo.png";


gsap.registerPlugin(ScrollTrigger);

let tl = gsap.timeline();
const Partners = () => {
    useEffect(()=>{
        gsap.from(".logo-img", {
            y: 100,
            opacity: 0,
            duration: 1.5,
            ease: "expo.inOut",
        });
        gsap.from(".logo-text", {
            y: 100,
            opacity: 0,
            duration: 1.5,
            ease: "expo.inOut",
        });
        gsap.from(".grop-one-detail p, .group-detail img , .group-detail", {
            y: 150,
            opacity: 0,
            duration: 1.5,
            ease: "expo.inOut",
        });
        gsap.from(".city-location li , .right-content-box , .content-right img", {
            x: 150,
            opacity: 0,
            duration: 1.5,
            ease: "expo.inOut",
        });
        gsap.from(".total-area", {
            x: 150,
            opacity: 0,
            duration: 1.5,
            ease: "expo.inOut",
        });
        tl.from(".our-partner.one .complete", {
            y: 100,
            opacity: 0,
            delay: 1,
            scrollTrigger: {
              trigger: ".our-partner.one ",
              start: "100px center",
              end: "100px center",
            },
            stagger: {
              // wrap advanced options in an object
              each: 0.1,
              ease: "power2.inOut",
            },
        });
        tl.to(".our-partner.one .complete", {
            y: 1,
            opacity: 1,
            delay: 1,
            scrollTrigger: {
              trigger: ".our-partner.one ",
              start: "100px center",
              end: "100px center",
            },
            stagger: {
              // wrap advanced options in an object
              each: 0.1,
              ease: "power2.inOut",
            },
        });
        gsap.from(".our-partner.two .complete", {
            y: 100,
            opacity: 0,
            delay: 1,
            scrollTrigger: {
              trigger: ".our-partner.two ",
              start: "300px center",
              end: "500px center",
            },
            stagger: {
              // wrap advanced options in an object
              each: 0.1,
              ease: "power2.inOut",
            },
        });
        gsap.to(".our-partner.two .complete", {
            y: 1,
            opacity: 1,
            delay: 1,
            scrollTrigger: {
              trigger: ".our-partner.two ",
              start: "300px center",
              end: "500px center",
            },
            stagger: {
              // wrap advanced options in an object
              each: 0.1,
              ease: "power2.inOut",
            },
        });
        gsap.from(".section-header", {
            y: 150,
            opacity: 0,
            duration: 1.5,
            ease: "expo.inOut",
        });
    })
    return (
        <>
            <section className='our-partner one'>
                <div className='container'>
                    <div className='row'>
                        <div className='logo col-sm-12'>
                            <div className='logo-img'> <img src='/assets/about/logo-1.png' alt='#' width='80' /> </div>
                            <div className='logo-text'>
                                <h2>SYNERGY GROUP</h2>
                                <p>innovating to create a stronger community</p>
                            </div>
                        </div>
                    </div>
                    <div className='details'>
                        <div className='row'>
                            <div className='col-12 col-sm-5 grop-one-detail'>
                                <div className='group-detail mb-4'> <img src='/assets/img-1.png' alt='#' className="mx-auto" /> </div>
                                <p>Started in 2010, under the leadership of <b>Mr. Anant Agarwal</b>, Synergy Group has always believed in an impactful work environment by creating <b>architecture that is most functional and people centric.</b> The underlying aim is to incapsulate <b>design, safety and convenience</b> at the heart of every project. </p>
                                <div className='group-detail'> <img src='/assets/cbh-logo.jpeg' alt='#' className="cbh-logo-img" />
                                    <p>An <b>air-conditioned shopping hub</b> chain with a cumulative built of <b>6,50,000 sq. ft</b>, which provides a premium quality commercial place to <b>local as well as big brand retails.</b></p>
                                </div>
                                <ul className='city-location'>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Vesu(Surat)</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Varachha(Surat)</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Vapi</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Navsari</span> </li>
                                </ul>
                                <div className='total-area'>
                                    <h2> 6,50,000</h2>
                                    <p>sq. ft</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-2 left-progress-years'>
                                <div className="progres">
                                    <div className="complete"><span>2021</span></div>
                                    <div className="complete"><span>2020</span></div>
                                    <div className="complete"><span>2019</span></div>
                                    <div className="complete"><span>2018</span></div>
                                    <div className="complete"><span>2017</span></div>
                                    <div className="complete"><span>2016</span></div>
                                    <div className="complete"><span>2015</span></div>
                                    <div className="complete"><span>2014</span></div>
                                    <div className="complete"><span>2013</span></div>
                                    <div className="complete"><span>2012</span></div>
                                    <div className="complete"><span>2011</span></div>
                                    <div className="complete"><span>2010</span></div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-5 grop-one-detail'>
                                <p>Synergy Group stands on three pillars - faith, integrity and excellence. We have devoted ourselves to our revered costumers - to their safety and satisfaction and to providing them with avant-garde and sustainable infrastructure. </p>
                                <ul className='right-content-box'>
                                    <li>FAITH</li>
                                    <li>INTEGRITY</li>
                                    <li>EXCELLENCE</li>
                                </ul>
                                <p> We aim to expand our footprint in the coming years in Surat and various tier 2 and sub-tier 2 cities in Western India by providing innovative solutions to people’s space needs with new commercial and office projects planned. </p>
                                <div className='content-right'> <img src='/assets/about/iso.png' alt='#' width='100' />
                                    <p> Synergy Group is certified by the ISO 9001, 14001 and 45001 standards which demonstrates our uncompromising adherence to the best quality standards. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='our-partner two'>
                <div className='container'>
                    <div className='row'>
                        <div className='logo col-12 col-sm-12'>
                            <div className='logo-img'> <img src='/assets/about/logo-2.png' alt='#' width='80' /> </div>
                            <div className='logo-text'>
                                <h2>SWAMI GROUP</h2>
                                <p>setting new standards with every project</p>
                            </div>
                        </div>
                    </div>
                    <div className='details'>
                        <div className='row'>
                            <div className='col-12 col-sm-5 grop-one-detail'>
                                <div className='group-detail mb-4 mt-0 two-image'> 
                                    <img src='/assets/01.png' alt='#' className="mx-auto" /> 
                                    <img src='/assets/02.png' alt='#' className="mx-auto" /> 
                                </div>
                                <p>Started in 2006 by <b>Manish Agrawal, Manoj Desai and Denish Shah,</b> Swami group has conceptualized numerous <b>residential plotting schemes across South Gujarat.</b> The group is <b>now</b> venturing out to build <b>innovative commercial projects</b>, to provide unique opportunities to its customers. </p>
                                <div className='group-detail'>
                                    <h3>TWIN CITY</h3>
                                    <h5>1 to 7 and farms</h5>
                                    <p>Swami Group is known to be positively laying down the foundations of land development for residential plots and is known for its benchmark twin city projects which are spread across 1,75,000 sq. meters of land. </p>
                                </div>
                                <ul className='city-location'>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Navsari</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Olpad</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Ubhrat</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Dolera*</span> </li>
                                </ul>
                                <ul className='city-location bottom'>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Saputara</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Daman</span> </li>
                                    <li> <img src='/assets/about/location.png' alt='#' /> <span>Pinjrat</span> </li>
                                </ul>
                                <div className='total-area mt-5'>
                                    <h2>4,00,000</h2>
                                    <p>sq. ft</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-2 left-progress-years'>
                                <div className="progres">
                                    <div className="complete"><span>2021</span></div>
                                    <div className="complete"><span>2020</span></div>
                                    <div className="complete"><span>2019</span></div>
                                    <div className="complete"><span>2018</span></div>
                                    <div className="complete"><span>2017</span></div>
                                    <div className="complete"><span>2016</span></div>
                                    <div className="complete"><span>2015</span></div>
                                    <div className="complete"><span>2014</span></div>
                                    <div className="complete"><span>2013</span></div>
                                    <div className="complete"><span>2012</span></div>
                                    <div className="complete"><span>2011</span></div>
                                    <div className="complete"><span>2010</span></div>
                                    <div className="complete"><span>2009</span></div>
                                    <div className="complete"><span>2008</span></div>
                                    <div className="complete"><span>2007</span></div>
                                    <div className="complete"><span>2006</span></div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-5 grop-one-detail'>
                                <div className='customer-count'>
                                    <h2>3,000 +</h2>
                                    <h4>customers</h4> <img src='/assets/about/map.png' id='right-amin-map' alt='#' />
                                </div>
                                <p>The group has catered to the needs of over 3000 satisfied customers across South Gujarat, North-west of Maharashtra and Union Territory of Daman, standing true to what it believes in. </p>
                                <ul className='right-content-box'>
                                    <li>FAITH</li>
                                    <li>INTEGRITY</li>
                                    <li>EXCELLENCE</li>
                                </ul>
                                <p> We aim to expand our footprint in the coming years in Surat and various tier 2 and sub-tier 2 cities in Western India by providing innovative solutions to people’s space needs with new commercial and office projects planned. </p>
                                <div className='mt-5'>
                                    <h3>4,00,000</h3>
                                    <h5>m 2 of land</h5>
                                    <p>in total till date</p>
                                    <p>With every project the group aims at excellence and improving the quality of its customers life. Having developed roughly over 4,00,000 sq. meters of land, the group has set new standards for quality land development. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
  

export default Partners;
