import React, { useState, useCallback } from "react";
import ImageViewer from 'react-simple-image-viewer';
const PastProjectList = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        '/assets/past-project-list/navsari.webp',
        '/assets/past-project-list/vapi.webp',
        '/assets/past-project-list/varachha.webp',
        '/assets/past-project-list/veneziano.webp',
        '/assets/past-project-list/vesu.webp',
    ];
    const ImageList = [
        {
            id:1,
            type: 1,
            url:'/assets/past-project-list/navsari.webp',
            category:'Navsari',
            title: '60,000',
            subtitle: 'March 2017'
        },
        {
            id:2,
            type: 3,
            url:'/assets/past-project-list/vapi.webp',
            category:'Vapi',
            title: '1,75,000',
            subtitle: 'Jan 2019'
        },
        {
            id:3,
            type: 2,
            url:'/assets/past-project-list/varachha.webp',
            category:'Varachha',
            title:'1,25,000',
            subtitle: 'Jan 2017'
        },
        {
            id:4,
            type: 1,
            url:'/assets/past-project-list/veneziano.webp',
            category:'Veneziano',
            title:'1,50,000',
            subtitle: 'October 2022'
        },
        {
            id:5,
            type: 2,
            url:'/assets/past-project-list/vesu.webp',
            category:'Vesu',
            title:'48,000',
            subtitle: 'Jan 2017'
        },
    ]

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <div className='past-project-list'>
            <div className='container'>
                <div className="project-list-tabs">
                    <div className='image-list row'>
                        {ImageList.map((item,index)=>{
                            return(
                                <div key={index} className='col-sm-6 col-md-4 tab-items'>
                                    <img src={item.url} alt="#" onClick={ () => openImageViewer(index) }/>
                                    <div className='tab-content'>
                                        <p>location : {item.category}</p>
                                        <h2><span>Total Area (Sq. Ft) :</span> {item.title}</h2>
                                        <h4><span>Completion Date :</span> {item.subtitle}</h4>
                                    </div>
                                </div>
                                )
                            })
                        }
                        {isViewerOpen && (
                            <div className="view-image-block">
                                <ImageViewer
                                    src={ images }
                                    currentIndex={ currentImage }
                                    disableScroll={ false }
                                    closeOnClickOutside={ true }
                                    onClose={ closeImageViewer }
                                />
                            </div>
                        )}
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default PastProjectList
