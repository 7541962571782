import React from 'react'

const OurClients = () => {
    return (
        <section id="clients" className="section-bg">
            <div className="container">
                <div className="section-header">
                    <h3>Meet Our Satisfied Clients</h3>
                </div>
                <div className="clients-wrap">
                    <div className="client-logo-item">
                        <div className="client-logo">
                            <img src="/assets/brands/big-bazaar.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/pantaloons.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/max.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/vijay-sales.webp" className="img-fluid" alt=""/>
                        </div>
                        
                    </div>
                    <div className="client-logo-item">
                        <div className="client-logo">
                            <img src="/assets/brands/ivenus.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo"> 
                            <img src="/assets/brands/adidas.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/puma.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/reebok-logo.webp" className="img-fluid" alt=""/>
                        </div>
                        
                    </div>
                    <div className="client-logo-item">
                        <div className="client-logo">
                            <img src="/assets/brands/us-polo-assn.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/pepe-jeans.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/bhatis.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/icici-bank.webp" className="img-fluid" alt=""/>
                        </div>
                        
                    </div>
                    <div className="client-logo-item">
                        <div className="client-logo">
                            <img src="/assets/brands/karurvysya.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/bharat.webp" className="img-fluid" alt=""/>
                        </div>
                        <div className="client-logo">
                            <img src="/assets/brands/coffee-culture.webp" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurClients
