import React, { useEffect } from 'react'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BgVideo from "../assets/cbh-animation.mp4";

gsap.registerPlugin(ScrollTrigger);

const CbhProjectStatus = () => {
    useEffect(() => {
        gsap.from(".timeLine__animationtwo", {
            y: -70,
            opacity: 0,
            scrollTrigger: {
                trigger: '.timeLine__animationtwo',
                start: 'top center',
            }
        })
        gsap.from(".timeLine__animationThree", {
            y: -70,
            opacity: 0,
            scrollTrigger: {
                trigger: '.timeLine__animationThree',
                start: 'top center',
            }
        })
        gsap.from(".timeLine__animationFour", {
            y: -70,
            opacity: 0,
            scrollTrigger: {
                trigger: '.timeLine__animationFour',
                start: 'top center',
            }
        })
        gsap.from(".timeLine__animationFive", {
            y: -70,
            opacity: 0,
            scrollTrigger: {
                trigger: '.timeLine__animationFive',
                start: 'top center',
            }
        })

    })
    return (
        <>
            <div className='project-status-timeline'>
                <div className='container'>
                    <div className='timeline-status timeLine__animationOne'>
                        <div className='row'>
                            <div className='col-12 col-md-7'>
                                <div className='image'>
                                    <img src='/assets/project-status/project-status-31jan.webp' alt='#' />
                                    <span className='timeline-year'>31 Jan 2022</span>
                                </div>
                            </div>
                            <div className='col-12 col-md-5 project-status-content'>
                                <div className='project-status'>
                                    <p>completion of ground and first floor Slab</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='timeline-status timeLine__animationtwo'>
                        <div className='row'>
                            <div className='col-12 col-md-7'>
                                <div className='image'>
                                    <img src='/assets/project-status/project-status-28feb.webp' alt='#' />
                                    <span className='timeline-year'>28 Feb 2022</span>
                                </div>
                            </div>
                            <div className='col-12 col-md-5 project-status-content'>
                                <div className='project-status'>
                                    <p>completion of second floor slab </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='timeline-status timeLine__animationThree'>
                        <div className='row'>
                            <div className='col-12 col-md-7'>
                                <div className='image'>
                                    <img src='/assets/project-status/project-status-31march.webp' alt='#' />
                                    <span className='timeline-year'>31 march 2022</span>
                                </div>
                            </div>
                            <div className='col-12 col-md-5 project-status-content'>
                                <div className='project-status'>
                                    <p>completion of external RCC & Plastering work</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='timeline-status timeLine__animationThree'>
                        <div className='row'>
                            <div className='col-12 col-md-7'>
                                <div className='image'>
                                    <img src='/assets/project-status/project-status-31december.png' alt='#' />
                                    <span className='timeline-year'>31 December 2022</span>
                                </div>
                            </div>
                            <div className='col-12 col-md-5 project-status-content'>
                                <div className='project-status'>
                                    <p>Final slab completed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='bg-video-block'>
                <div className='container'>
                    <div className="bg-video-wrap">
                        <video src={BgVideo} loop muted autoPlay>
                        </video>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CbhProjectStatus;
