import React from "react";
import Banner from "../components/home/Banner"
import ImgGallery from "../components/home/Imggallery";
import Building from "../components/home/building";
import BrandsLogo from "../components/home/brands";
import Interiors from "../components/home/Interiors";
import YoutubeVideo from "../components/home/YoutubeVideo";

const Home = () => {
  return (
    <div>
      <div className="home__wrapper">
        <Banner/>
        <Interiors/>
        <YoutubeVideo/>
        <Building/>
        <BrandsLogo/>
        <ImgGallery/>
      </div>
    </div>
  );
};

export default Home;
