import React from 'react'
// import ImageGallery from 'react-image-gallery';

const OurTeam = () => {

    return (
        <div className='our-team about'>
            <div className='container'>
                <div className="section-header">
                    <h3>Meet Our Team</h3>
                </div>
                <h4> Swami group</h4>
                <div className='row our-team-grid'>
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <div className='team-card'>
                            <div className='member-designation' >
                                <h2>Mr. Manish Agrawal</h2>
                                <p>A versatile man with strong business acumen, heads planning and strategic business development and plays an active role in developing business strategy for the group. </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <div className='team-card'>
                            <div className='member-designation' >
                                <h2>Mr. Manoj Desai</h2>
                                <p>A dynamic and committed leader with intrinsic decision-making ability which is a result of his extensive experience and incredible dedication.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <div className='team-card'>
                            <div className='member-designation' >
                                <h2>Mr. Denish Shah</h2>
                                <p>Is a multi-talented director who plays a vital role in managing and overseeing technical aspects of all Real Estate projects from conception to completion.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Synergy Group</h4>
                <div className='row our-team-grid'>
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <div className='team-card'>
                            <div className='member-designation' >
                                <h2>Mr. Anant Agrawal</h2>
                                <p>Is Director at Synergy Group and has an experience of 10 years in the real estate development industry in South Gujarat, with his family being in the business since the 1980s. He holds an MBA (Finance) and BCom, both from the South Gujarat University, Surat, India.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <div className='team-card'>
                            <div className='member-designation' >
                                <h2>Mr. Anurav Agrawal</h2>
                                <p>Is Director at Synergy Group and has an educational background of MSc Real Estate Economics and Finance from the London School of Economics and Political Science (LSE) and BSc Business Management from King’s College London (KCL). He oversees the communications and operations side of the firm.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTeam;
