import React from 'react'

const ImgGallery = () => {

    return (
        <section className='image-gallery-block'>
            <div className='container'>
            <div className='main-title'>
                <h2>Providing New dimensions to businesses</h2>
            </div>
                <div className='row'>
                    <div className='col-sm-6 col-lg-4 card-1 cards-items'>
                        <div className='card-img'>
                            <img src='/assets/cafeteria.webp' alt='#' />
                        </div>
                        <div className='card-content'>
                            <h3>Cafeteria</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 card-1 cards-items'>
                        <div className='card-img'>
                            <img src='/assets/conference-room.webp' alt='#' />
                        </div>
                        <div className='card-content'>
                            <h3>Conference Room</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 card-1 cards-items'>
                        <div className='card-img'>
                            <img src='/assets/office-elevators.webp' alt='#' />
                        </div>
                        <div className='card-content'>
                            <h3>Six Office Elevators</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 card-1 cards-items'>
                        <div className='card-img'>
                            <img src='/assets/elevators.webp' alt='#' />
                        </div>
                        <div className='card-content'>
                            <h3>Two Escalators</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 card-1 cards-items'>
                        <div className='card-img'>
                            <img src='/assets/ample-parking.webp' alt='#' />
                        </div>
                        <div className='card-content'>
                            <h3>Four Level Car Parking</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 card-1 cards-items'>
                        <div className='card-img'>
                            <img src='/assets/skydeck.png' alt='#' />
                        </div>
                        <div className='card-content'>
                            <h3>Skydeck</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImgGallery
